<script>
    export let players = [];
    function reLogin(character) {
        dispatch('return_to_role', { character });
    }
    import { texts } from './texts.js';
    export let lang = 'pl';
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    $: console.log('players', players);
</script>

<div class="u-background--popup">
    <div class="lost-players">
        <h class="h-middle">{texts[lang].lost_players}</h>
        {#each players as player}
            {#if !player.isOnline}
                <div
                    class="players-list__item"
                    on:click={() => reLogin(player.character)}
                >
                    <div
                        class="player-item__img player-item__img--{player.character}"
                        class:u-offline={!player.isOnline}
                    />
                    <div class="player-item__data">
                        <span class="h-small"
                            >{texts[lang][player.character].label}</span
                        >
                        <p class="p-small">
                            {player.playerName}
                        </p>
                    </div>
                </div>
            {/if}
        {/each}
    </div>
</div>
