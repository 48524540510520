<script>
    import { tick } from 'svelte';
    import { bridgeParams } from './bridgeParams';
    import { texts } from './texts';
    export let lang = 'eng';
    import io from 'socket.io-client';
    import Swal from 'sweetalert2';
    import ReconnectPlayer from './ReconnectPlayer.svelte';
    import Header from './Header.svelte';
    import BridgeParts from './BridgeParts.svelte';
    import BridgeVisualisation from './BridgeVisualisation.svelte';
    import Footer from './Footer.svelte';
    import Sheep from './Sheep.svelte';
    import FooterConsultant from './FooterConsultant.svelte';
    import Loading from './Loading.svelte';
    import Voting from './Voting.svelte';
    import Timer from './Timer.svelte';
    import Manual from './Manual.svelte';
    import EngineerManual from './EngineerManual.svelte';
    import LetterMayor from './LetterMayor.svelte';
    const connectionData = new URL(window.location);
    const gameLocation = connectionData.searchParams.get('game');
    const role = connectionData.searchParams.get('player');
    export let councilCount = 0;
    export let timerTools = {};
    export let timerState = true;
    export let timer = 0;
    export let timeOfDestroyTimer = 0;
    export let bridgeState = {};
    export let viewState = {
        isVotingRunning: false,
        isVotingOver: false,
        isSolutionVisible: false,
        isSheepingActive: false,
    };
    export let safetyState = {
        height: 0,
        width: 0,
        pillars: 0,
        railings: 0,
        finish: 0,
        leaderstatue: 0,
        chamoissatue: 0,
        guardpost: 0,
    };

    function aggregateSafety(state) {
        bridgeParams.forEach((param) => {
            if (!param.isBoolean) {
                const selectedOption = param.options.find(
                    (el) => el.title === state[param.name]
                );
                safetyState[param.name] = selectedOption.safety;
            } else {
                safetyState[param.name] = bridgeState[param.name] ? -1 : 0;
            }
        });
        let _sum = 0;
        for (let prop in safetyState) {
            if (prop !== 'total') {
                _sum = _sum + safetyState[prop];
            }
        }
        safetyState.total = _sum;
    }

    export let myGame = '';
    export let isGameOn = true;
    export let isGameEnd = false;

    let isReconnectMode = false;
    let gameSocket = '';
    let myChar = '';
    export let isLetterVisible = false;
    export let isManualVisible = false;
    export let isNoAgreement = false;
    export let isTrainerSummoned = false;

    export let playerList = {
        council: [],
        engineers: [],
    };
    initGame();

    function initGame() {
        if (!gameLocation || !role) {
            Swal.fire({
                title: texts[lang].missing_credentials,
                backdrop: false,
            });
            return;
        }
        const checkSocket = io({
            query: { game: gameLocation },
        });
        checkSocket.on('hello', () => {
            console.log(`access granted, conneting to ${gameLocation}`);
            gameLogin(gameLocation);
            checkSocket.disconnect();
        });
        checkSocket.on('invalid', () => (myGame = 'bzzzzzzzzzzzzz'));
    }
    async function gameLogin(game) {
        gameSocket = io(`/${game}`);

        gameSocket.on('hello in the game', async (data) => {
            console.log('got data', data);

            isGameOn = data.isGameOn;
            isGameEnd = data.isGameEnd;
            lang = data.lang;

            if (!isGameOn && !isGameEnd) {
                const { value: playerName } = await Swal.fire({
                    title: 'Input your nickname/Podaj swoje imię/pseudonim',
                    input: 'text',
                    allowOutsideClick: false,
                    backdrop: false,
                    inputValidator: (value) => {
                        if (!value) {
                            return '!';
                        }
                        if (value.length > 16) {
                            return 'max 16 znaków/max 16 chars';
                        }
                    },
                });
                gameSocket.emit('new_user', { name: playerName, role });
            } else {
                //TODO: localStorage support
                gameSocket.emit('new_user', { name: 'unknown', role });
            }
        });
        gameSocket.on('connect_to_running_game', (data) => {
            console.log('rec', data);
            playerList[role] = data.players;
            lang = data.lang;
            isReconnectMode = true;
        });
        gameSocket.on('no_access', (data) => {
            myGame = JSON.stringify(data);
        });
        gameSocket.on('logged_in', async (data) => {
            isReconnectMode = false;
            myChar = data.player;
            playerList.engineers = data.playersList.engineers.players;
            playerList.council = data.playersList.council.players;
            councilCount = data.playersList.council.max;
            console.log('logged in');
            bridgeState = data.bridgeState;
            lang = data.lang;
            viewState = data.viewState;
            console.log('after login', playerList);
            if (data.timerData) {
                const now = Date.now();
                console.log(now, '    ', data.timerData.startTime);
                const diff = Math.floor(
                    (now - 300 - data.timerData.startTime) / 1000
                );
                console.log(diff, '    ', timer);
                await tick();
                if (data.timerData.duration * 60 - diff > 0) {
                    timerTools.startTimer(
                        data.timerData.duration * 60 - diff,
                        true
                    );
                }
            }
        });
        gameSocket.on('userlist', (data) => {
            console.log('userlist!', data);
            playerList.engineers = data.engineers.players;
            playerList.council = data.council.players;
            councilCount = data.council.max;
        });
        gameSocket.on('bridge_changed_on_server', (data) => {
            bridgeState = data;
        });
        gameSocket.on('game_started', (data) => {
            isGameOn = data;
            isManualVisible = true;
        });
        gameSocket.on('trainer_started_timer', (time) => {
            timerTools.startTimer(time);
        });
        gameSocket.on('trainer_started_voting', (data) => {
            viewState = data.viewState;
            bridgeState = data.bridgeState;
            playerList.council = data.players;
        });
        gameSocket.on('trainer_ended_voting', (data) => {
            playerList.council = data.players;
            viewState = data.viewState;
        });
        gameSocket.on('voting_not_agreed', async (data) => {
            await Swal.fire({
                text: texts[lang].voting_not_agreed,
                backdrop: false,
            });
            viewState = data.viewState;
            playerList.council = data.players;
        });

        gameSocket.on('voting_break', (data) => {
            viewState = data;
        });
        gameSocket.on('toggle_solution', (data) => {
            viewState = data;
        });
        gameSocket.on('toggle_sheep', (data) => {
            viewState = data.viewState;
            playerList.council = data.players;
        });
        gameSocket.on('sheeping_ended', (data) => {
            viewState = data.viewState;
            playerList.council = data.players;
            console.log('after sheeping', playerList.council);
        });
        gameSocket.on('trainer_desummoned', () => (isTrainerSummoned = false));
        gameSocket.on('trainer_summoned', () => (isTrainerSummoned = true));
    }
    $: if (Object.keys(bridgeState).length > 0) aggregateSafety(bridgeState);
</script>

<svelte:head>
    <title>{lang ? texts[lang].header_primary : '...'}</title>
    <html lang=lang />
</svelte:head>

{#if isReconnectMode}
    <ReconnectPlayer
        {lang}
        players={playerList[role].players}
        on:return_to_role={(ev) => {
            console.log(role);
            gameSocket.emit('return', { role, character: ev.detail.character });
        }}
    />
{:else if myChar}
    {#if viewState.isSheepingActive}
        <Sheep
            {lang}
            {viewState}
            {myChar}
            {role}
            players={playerList.council}
            on:sheeps_assigned={(ev) => {
                gameSocket.emit('player_assigned_sheeps', ev.detail);
            }}
        />
    {:else if viewState.isVotingRunning}
        <Voting
            {lang}
            {role}
            {bridgeState}
            {safetyState}
            bind:viewState
            {myChar}
            {playerList}
            {councilCount}
            {isNoAgreement}
            on:vote={(ev) => gameSocket.emit('player_vote', ev.detail)}
        />
    {:else if isLetterVisible}
        <LetterMayor bind:isLetterVisible {myChar} {lang} />
    {:else if isManualVisible}
        {#if role === 'council'}
            <Manual {role} {myChar} {lang} bind:isManualVisible />
        {:else if role === 'engineers'}
            <EngineerManual
                {role}
                {lang}
                {myChar}
                bind:isManualVisible
                {councilCount}
            />
        {/if}
    {:else}
        <div class="u-background">
            <Header
                {councilCount}
                {isTrainerSummoned}
                {lang}
                {role}
                {playerList}
                {myChar}
                bind:isLetterVisible
                bind:isManualVisible
                on:summon_trainer={() => gameSocket.emit('summon_trainer')}
            >
                <Timer
                    bind:timerState
                    bind:timerTools
                    bind:timer
                    bind:timeOfDestroyTimer
                />
            </Header>
            <div class="bridge-content">
                <BridgeVisualisation {bridgeState} {lang} {role} {viewState} />
                {#if role === 'council'}<BridgeParts
                        {lang}
                        bind:bridgeState
                        on:send_bridge={() => {
                            console.log('sending', bridgeState);
                            gameSocket.emit('bridge_changed', bridgeState);
                        }}
                    />
                {/if}
            </div>
        </div>

        {#if role === 'council'}
            <Footer
                {bridgeState}
                {lang}
                objectives={myChar.gameLogic.objectives}
                character={myChar.character}
            />
        {:else if role === 'engineers'}
            <FooterConsultant
                {lang}
                bind:bridgeState
                bind:safetyState
                on:send_bridge={() => {
                    gameSocket.emit('bridge_changed', bridgeState);
                }}
            />
        {/if}
    {/if}
{:else}
    <Loading {lang} {isGameEnd} />
{/if}
