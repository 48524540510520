<script>
    import { bridgeParams } from './bridgeParams.js';
    export let bridgeState;
    export let role;
    export let viewState = {};
    let h3 = !viewState.isVotingRunning ? '-2.3vw' : '-.8vw';
    let h2 = !viewState.isVotingRunning ? '-1.3vw' : '-.3vw';

    // $: console.log('vis', bridgeState);

    document.documentElement.style.setProperty('--temporary-height-three', h3);
    document.documentElement.style.setProperty('--temporary-height-two', h2);
</script>

<div class="bridge__voting bridge__{role}">
    {#each bridgeParams as param}
        {#if !param.isBoolean}
            {#each param.options as option}
                <input
                    type="radio"
                    name="mask-{param.name}"
                    bind:group={bridgeState[param.name]}
                    value={option.title}
                    id="{option.title}_visual"
                    class="radio "
                />
                <label for="{option.title}_visual"> {option.title}</label>
            {/each}
        {:else}
            <!-- co ma być jak jest boolean? -->
            <input
                type="checkbox"
                bind:checked={bridgeState[param.name]}
                name={param.name}
                value={param.name}
                id="{param.name}_vis"
                class="checkbox"
            />
            <label for="{param.name}_vis">{param.name}</label>
        {/if}
    {/each}

    <div class="mask__background-bottom" />
    <div class="mask__background-top" />
    <div class="mask__main-state--base" />
    <div class="mask__main-state--middle" />
    <div class="mask__main-state--final" />
    <div class="mask__pillars-one" />
    <div class="mask__pillars-two" />
    <div class="mask__pillars-three" />
    <div class="mask__railings-none--left" />
    <div class="mask__railings-parapet--left" />
    <div class="mask__railings-wall--left" />
    <div class="mask__railings-none--right" />
    <div class="mask__railings-parapet--right" />
    <div class="mask__railings-wall--right" />
    <div class="mask__other-chamoisstatue" />
    <div class="mask__other-leaderstatue" />
    <div class="mask__other-guardpost" />
</div>
