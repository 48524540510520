<script>
    import { CONFIG } from '../config.js';
    import Swal from 'sweetalert2';
    export let bridgeState = {};
    import { bridgeParams } from './bridgeParams.js';
    import { texts } from './texts';
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    export let lang = 'pl';
    let sendMe = false;
    function sendBridge() {
        sendMe = false;
        dispatch('send_bridge');
        // dymek, że wysłano
        // Swal.fire({
        //     title: 'Wysłano!',
        //     toast: true,
        //     position: 'top-end',
        //     timer: 1000,
        //     showConfirmButton: false,
        // });
    }
</script>

<div class="bridge-parts">
    <!-- u-attention to klasa na "miga - podejmij jakąś akcję" -->
    <button class="btn-stone" class:u-attention={sendMe} on:click={sendBridge}>
        <span class="btn__text">{texts[lang].show_bridge}</span>
    </button>
    <div class="bridge-parts__title">
        <span class="h-glow">{texts[lang].bridge_parts}</span>
    </div>
    <div class="bridge-parts__content">
        {#each bridgeParams as param}
            {#if !param.isBoolean}
                <div class="bridge-item">
                    <input
                        type="checkbox"
                        id="bridge-list-{param.name}"
                        class="checkbox bridge-checkbox"
                    />
                    <label
                        for="bridge-list-{param.name}"
                        class="bridge-label"
                        style="background: url({CONFIG.filesUrl}/img/bridge-parts/{param.name}.png)"
                    >
                        <span class="p-glow">{texts[lang][param.name]}</span>
                    </label>

                    <div class="bridge-item__options">
                        {#each param.options as option}
                            <input
                                type="radio"
                                bind:group={bridgeState[param.name]}
                                name={param.name}
                                value={option.title}
                                id={option.title}
                                class="radio"
                                on:change={() => (sendMe = true)}
                            />
                            <label
                                for={option.title}
                                class="option-item"
                                class:option-item--active={bridgeState[
                                    param.name
                                ] === option.title}
                                style="background-image: url({CONFIG.filesUrl}/img/bridge-parts/{option.title}.png"
                            >
                                <span class="p-glow option-item__heading"
                                    >{texts[lang][option.title]}</span
                                >
                            </label>
                        {/each}
                    </div>
                </div>
            {/if}
        {/each}
        <div class="bridge-item">
            <input
                type="checkbox"
                id="bridge-list-other"
                class="checkbox bridge-checkbox"
            />
            <label
                for="bridge-list-other"
                class="bridge-label bridge-label--other"
            >
                <span class="p-glow ">{texts[lang].other}</span>
            </label>
            <div class="bridge-item__options">
                {#each bridgeParams as param}
                    {#if param.isBoolean}
                        <input
                            type="checkbox"
                            bind:checked={bridgeState[param.name]}
                            name={param.name}
                            value={param.isSelected}
                            id={param.name}
                            class="checkbox"
                            on:change={() => (sendMe = true)}
                        />
                        <label
                            for={param.name}
                            class="option-item"
                            class:option-item--active={bridgeState[param.name]}
                            class:selected={bridgeState[param.name]}
                            style="background: url({CONFIG.filesUrl}/img/bridge-parts/{param.name}.png)"
                        >
                            <span class="p-glow option-item__heading"
                                >{texts[lang][param.name]}{bridgeState[
                                    param.name
                                ]
                                    ? ''
                                    : ''}</span
                            >
                        </label>
                    {/if}
                {/each}
            </div>
        </div>
    </div>
</div>
