<script>
    export let lang = 'pl';
    import { texts } from './texts.js';
    let sheepScore = 0;
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    let isSend = false;
    export let viewState = {};
    export let myChar = {};
    export let players = {};
    export let role;
    $: if (myChar.sheepScore) {
        sheepScore = myChar.sheepScore;
        isSend = true;
    }
</script>

<div class="u-background--popup">
    <div class="voting-container">
        <div class="voting-container__title">
            <span class="h-main"
                >{viewState.isSheepingOver === false
                    ? texts[lang].sheep_title
                    : texts[lang].sheep_title2}</span
            >
        </div>

        {#if !viewState.isSheepingOver}
            {#if role === 'council'}
                <div class="sheep__content">
                    <span class="p-big">{texts[lang].sheep_heading}</span>
                    <div class="sheep__content-engineers" />
                    <div class="sheep__content-sheep">
                        {#each { length: 5 } as sheep, id}
                            <input
                                type="radio"
                                class="radio sheep-radio"
                                id="sheep{5 - id}"
                                name="sheep{5 - id}"
                                value={5 - id}
                                bind:group={sheepScore}
                            />
                            <label
                                for="sheep{5 - id}"
                                class="sheep sheep--{5 - id}"
                            />
                        {/each}
                    </div>
                    <div class="sheep__content-approve">
                        <button
                            on:click={() => {
                                if (sheepScore > 0) {
                                    isSend = true;
                                    dispatch('sheeps_assigned', sheepScore);
                                }
                            }}
                            class="btn-voting btn-voting--approve {isSend
                                ? 'btn-voting--inactive'
                                : ''}">{texts[lang].voting_approve}</button
                        >
                    </div>
                </div>
            {:else}
                <div class="sheep__content-engineers" />
                <span class="h-main"
                    >{texts[lang].sheeping_placeholder_engineer}</span
                >
            {/if}
        {:else}
            <div class="sheep__result">
                <span class="h-small">{texts[lang].sheep_heading2}</span>
                {#each players as player}
                    {#if !(player.character === 'mayor' && player.playerName === 'Automa')}
                        <div class="sheep__result-player">
                            <div class="players-list__item">
                                <div
                                    class="player-item__img player-item__img--{player.character}"
                                />
                                <div class="player-item__data">
                                    <span class="h-small"
                                        >{texts[lang][player.character]
                                            .label}</span
                                    >
                                    <p class="p-glow">{player.playerName}</p>
                                </div>
                            </div>
                            <div
                                class="sheep-list sheep-list--{player.sheepScore}"
                            />
                        </div>
                    {/if}
                {/each}
            </div>
            <div class="sheep__result-engineers" />
        {/if}
    </div>
</div>
