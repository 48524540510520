<script>
    export let character = '';
    export let objective = '';
    export let bridgeState = {};
    import { texts } from './texts.js';
    export let lang = 'pl';
    let statusClass = '';
    import { objectivesValidator } from './characters.js';
    $: if (objective.needsChecking && !objective.relative) {
        objective.isAchieved =
            objectivesValidator[character][objective.label](bridgeState);
    } else if (objective.relative) {
        statusClass = 'objective-card__status--unknown';
    }

    $: if (objective.isAchieved === 'unknown') {
        statusClass = 'objective-card__status--unknown';
    } else if (objective.isAchieved) {
        statusClass = 'objective-card__status--done';
    } else {
        statusClass = '';
    }
</script>

<input
    type="checkbox"
    class="checkbox objective-checkbox{objective.id}"
    id="objective{objective.id}"
    name="objective{objective.id}"
/>
<label
    for="objective{objective.id}"
    class="objective-card objective-card--{objective.id}"
>
    <div
        class="objective-card__icon "
        class:objective-img--relative={objective.relative}
    >
        <img
            src="https://mindlab-zulu-files.s3.eu-central-1.amazonaws.com/img/bridge-parts/{objective.icon}.png"
            alt="objective"
            class="u-objective-img"
        />
    </div>
    {#if objective.needsChecking}<div
            class="objective-card__status {statusClass}"
        />
    {/if}
    <hr class="u-hr--card" />
    <div class="objective-card__content">
        <p class="p-standard">
            {texts[lang][character][objective.description1]}
        </p>
        <p class="p-standard p-standard--bold">
            {texts[lang][character][objective.description2]}
        </p>
    </div>
</label>
