<script>
    import PlayerCard from './PlayerCard.svelte';
    export let isManualVisible = false;
    export let myChar = {};
    import { texts } from './texts.js';
    export let lang = 'pl';
    export let councilCount = '0';
    $: console.log(councilCount);
</script>

<div class="u-background--popup">
    <div class="instruction__popup instruction__popup--high">
        <button class="btn-close" on:click={() => (isManualVisible = false)} />
        <PlayerCard {lang} {myChar} {isManualVisible} />
        <div class="consultant-content">
            <div class="consultant-content__data">
                <div class="instruction-content__title">
                    <span class="h-main"
                        >{texts[lang].engineer_manual.title}</span
                    >
                </div>
                <input
                type="checkbox"
                class="checkbox page-change"
                id="page-change"
                name="page-change"/>
                <label
                for="page-change"
                class="p-standard page-change"
                >
                {texts[lang].page_change}
            </label>
                
            

                <p class="p-big">
                    {texts[lang].engineer_manual.manual1a}<span
                        class="p-standard--bold"
                        >{texts[lang].engineer_manual.manual1b}</span
                    >{texts[lang].engineer_manual.manual1c}
                </p>
                <p class="p-big">
                    {texts[lang].engineer_manual.manual2a}<span
                        class="p-standard--bold"
                        >{texts[lang].engineer_manual.manual2b}</span
                    >
                </p>

                <div class="icon-container">
                    <div
                        class="icon-container__img icon-container__img--width"
                    />
                    <div
                        class="icon-container__img icon-container__img--height"
                    />
                    <div
                        class="icon-container__img icon-container__img--pillars"
                    />
                    <div
                        class="icon-container__img icon-container__img--railings"
                    />
                    <div
                        class="icon-container__img icon-container__img--finish"
                    />
                    <div
                        class="icon-container__img icon-container__img--other"
                    />
                </div>
                <p class="p-big">
                    {texts[lang].engineer_manual.manual3a}<span
                        class="p-standard--bold"
                        >{texts[lang].engineer_manual.manual3b}</span
                    >
                    {texts[lang].engineer_manual.manual3c}
                </p>

                <p class="p-big">
                    {texts[lang].engineer_manual.manual4a}
                </p>
                <p class="p-big">
                    {texts[lang].engineer_manual.manual5a}
                </p>
                <p class="p-big">
                    {texts[lang].engineer_manual.manual6a}
                </p>
            </div>
            <div class="consultant-content__points">
                <div class="instruction-content__title">
                    <span class="h-main">
                        {texts[lang].engineer_manual.title__points}</span
                    >
                </div>
                <p class="p-standard p-standard--bold">
                    {texts[lang].engineer_manual.manual7}
                </p>
                <div class="instruction-content__important">
                    <p class="p-standard p-standard--bold">
                        {texts[lang].engineer_manual.manual8}
                    </p>
                    <p class="p-big">
                        {texts[lang].engineer_manual.manual9}
                    </p>
                </div>
                <hr class="u-hr--standard" />
                <div class="instruction-content__important">
                    <p class="p-big">
                        {texts[lang].engineer_manual.manual10}
                    </p>
                </div>
                <div class="characters-collage">
                    {#each { length: councilCount } as _, i}
                        <div class="collage-img collage-img--{i + 1}" />
                    {/each}
                </div>
            </div>
        </div>
    </div>
</div>
