exports.objectivesValidator = {
    mayor: {
        height(bridgeState) {
            if (bridgeState.height === 'height_three') {
                return true;
            } else {
                return false;
            }
        },
        width(bridgeState) {
            if (bridgeState.width === 'width_three') {
                return true;
            } else {
                return false;
            }
        },
        railings(bridgeState) {
            if (bridgeState.railings === 'railings_parapet') {
                return true;
            } else {
                return false;
            }
        },
        leaderstatue(bridgeState) {
            if (bridgeState.leaderstatue) {
                return true;
            } else {
                return false;
            }
        },
    },
    brother: {
        pillars(bridgeState) {
            if (bridgeState.pillars === 'pillars_three') {
                return true;
            } else {
                return false;
            }
        },
        finish(bridgeState) {
            if (bridgeState.finish === 'finish_stone') {
                return true;
            } else {
                return false;
            }
        },
        railings(bridgeState) {
            if (bridgeState.railings === 'railings_none') {
                return true;
            } else {
                return false;
            }
        },
        mayor(players) {
            const mayorScore = players.find((el) => el.character === 'mayor')
                .gameLogic.score;

            if (mayorScore >= 2) {
                return true;
            } else {
                return false;
            }
        },
    },
    captain: {
        railings(bridgeState) {
            if (bridgeState.railings === 'railings_wall') {
                return true;
            } else {
                return false;
            }
        },
        guardpost(bridgeState) {
            if (bridgeState.guardpost) {
                return true;
            } else {
                return false;
            }
        },
        height(bridgeState) {
            if (bridgeState.height === 'height_two') {
                return true;
            } else {
                return false;
            }
        },
        finish(bridgeState) {
            if (bridgeState.finish === 'finish_wooden') {
                return true;
            } else {
                return false;
            }
        },
    },
    sorcerer: {
        width(bridgeState) {
            if (bridgeState.width === 'width_one') {
                return true;
            } else {
                return false;
            }
        },
        pillars(bridgeState) {
            if (bridgeState.pillars === 'pillars_two') {
                return true;
            } else {
                return false;
            }
        },
        finish(bridgeState) {
            if (bridgeState.finish === 'finish_soil') {
                return true;
            } else {
                return false;
            }
        },
        captain(players) {
            const captainScore = players.find(
                (el) => el.character === 'captain'
            ).gameLogic.score;

            if (captainScore <= 2) {
                return true;
            } else {
                return false;
            }
        },
    },
    handyman: {
        pillars(bridgeState) {
            if (bridgeState.pillars === 'pillars_one') {
                return true;
            } else {
                return false;
            }
        },
        height(bridgeState) {
            if (bridgeState.height === 'height_one') {
                return true;
            } else {
                return false;
            }
        },
        finish(bridgeState) {
            if (bridgeState.finish !== 'finish_soil') {
                return true;
            } else {
                return false;
            }
        },

        sorcerer(players) {
            const sorcScore = players.find((el) => el.character === 'sorcerer')
                .gameLogic.score;

            if (sorcScore >= 2) {
                return true;
            } else {
                return false;
            }
        },
    },
    chairwoman: {
        chamoisstatue(bridgeState) {
            if (bridgeState.chamoisstatue) {
                return true;
            } else {
                return false;
            }
        },
        railings(bridgeState) {
            if (bridgeState.railings === 'railings_parapet') {
                return true;
            } else {
                return false;
            }
        },
        politics(players) {
            const mayorScore = players.find((el) => el.character === 'mayor')
                .gameLogic.score;
            const captainScore = players.find(
                (el) => el.character === 'captain'
            ).gameLogic.score;

            if (mayorScore + captainScore >= 3) {
                return true;
            } else {
                return false;
            }
        },
        height(bridgeState) {
            if (bridgeState.height === 'height_one') {
                return true;
            } else {
                return false;
            }
        },
    },
};
