export const bridgeParams = [
    {
        name: 'height',
        options: [
            { name: 'h_one', title: 'height_one', safety: -1 },
            { name: 'h_two', title: 'height_two', safety: 2 },
            { name: 'h_three', title: 'height_three', safety: -1 },
        ],
        selected: 'one',
        isBoolean: false,
    },
    {
        name: 'width',
        options: [
            { name: 'w_one', title: 'width_one', safety: -1 },
            { name: 'w_two', title: 'width_two', safety: 0 },
            { name: 'w_three', title: 'width_three', safety: 2 },
        ],
        selected: 'one',
        isBoolean: false,
    },
    {
        name: 'pillars',
        options: [
            { name: 'p_one', title: 'pillars_one', safety: -1 },
            { name: 'p_two', title: 'pillars_two', safety: 2 },
            { name: 'p_three', title: 'pillars_three', safety: -1 },
        ],
        selected: 'two',
        isBoolean: false,
    },
    {
        name: 'railings',
        options: [
            { name: 'r_none', title: 'railings_none', safety: 2 },
            { name: 'r_parapet', title: 'railings_parapet', safety: 0 },
            { name: 'r_wall', title: 'railings_wall', safety: -1 },
        ],
        selected: 'none',
        isBoolean: false,
    },
    {
        name: 'finish',
        options: [
            { name: 'f_soil', title: 'finish_soil', safety: 0 },
            { name: 'f_wooden', title: 'finish_wooden', safety: 0 },
            { name: 'f_stone', title: 'finish_stone', safety: 0 },
        ],
        selected: 'two',
        isBoolean: false,
    },
    {
        name: 'leaderstatue',
        safety: -1,
        isBoolean: true,
    },
    {
        name: 'guardpost',
        safety: -1,
        isBoolean: true,
    },
    {
        name: 'chamoisstatue',
        safety: -1,
        isBoolean: true,
    },
];
