<script>
    export let player = {};
    import { texts } from './texts';
    export let lang = 'pl'
</script>

<div class="players-list__item">
    <div
        class="player-item__img player-item__img--{player.character}"
        class:u-offline={!player.isOnline}
    />
    <div class="player-item__data">
        <span class="h-small">{texts[lang][player.character].label}</span>
        <p class="p-glow">
            {player.playerName}
        </p>
    </div>
</div>
