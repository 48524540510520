<script>
    export let isLetterVisible = false;
    import PlayerCard from './PlayerCard.svelte';
    import { texts } from './texts.js';
    export let lang = 'pl';
    export let myChar = {};
</script>

<div class="u-background--popup">
    <div class="instruction__popup instruction__popup--high">
        <button class="btn-close" on:click={() => (isLetterVisible = false)} />
        <PlayerCard {myChar} {lang} {isLetterVisible} />
        <div class="letter-content">
            <div class="letter-content__introduction">
                <div class="instruction-content__title">
                    <span class="h-main"
                        >{texts[lang].letter_mayor.letter_title1}</span
                    >
                </div>
                <p class="p-big">
                    {texts[lang].letter_mayor.letter1}
                </p>
                <p class="p-big">
                    {texts[lang].letter_mayor.letter2}
                </p>
                <p class="p-big">
                    {texts[lang].letter_mayor.letter3}
                </p>
                <div class="letter-content__important">
                    <div
                        class="instruction-content__icon instruction-content__icon--objective1"
                    />
                    <p class="p-standard">
                        {texts[lang].letter_mayor.letter5a}
                    </p>
                    <p class="p-standard p-standard--bold">
                        {texts[lang].letter_mayor.letter5b}
                    </p>
                </div>
                <div class="letter-content__important">
                    <div
                        class="instruction-content__icon instruction-content__icon--objective2"
                    />
                    <p class="p-standard">
                        {texts[lang].letter_mayor.letter6a}
                    </p>
                    <p class="p-standard p-standard--bold">
                        {texts[lang].letter_mayor.letter6b}
                    </p>
                </div>
            </div>
            <div class="letter-content__data">
                <div class="instruction-content__title">
                    <span class="h-main"
                        >{texts[lang].letter_mayor.letter_title2}</span
                    >
                </div>
                <div class="letter-content__important">
                    <div
                        class="instruction-content__icon instruction-content__icon--objective3"
                    />
                    <p class="p-standard">
                        {texts[lang].letter_mayor.letter7a}
                    </p>
                    <p class="p-standard p-standard--bold">
                        {texts[lang].letter_mayor.letter7b}
                    </p>
                </div>
                <div class="letter-content__important">
                    <div
                        class="instruction-content__icon instruction-content__icon--objective4"
                    />
                    <p class="p-standard">
                        {texts[lang].letter_mayor.letter8a}
                    </p>
                    <p class="p-standard p-standard--bold">
                        {texts[lang].letter_mayor.letter8b}
                    </p>
                </div>
                <p class="p-big"> {texts[lang].letter_mayor.letter9a}</p>
                <p class="p-big">{texts[lang].letter_mayor.letter4}</p>
                <p class="p-standard p-standard--bold"> {texts[lang].letter_mayor.letter9b}</p>
                

            </div>
        </div>
    </div>
</div>
