<script>
    export let myChar = {};
    export let isLetterVisible = false;
    export let isManualVisible = false;
    export let viewState = {};
    export let councilCount = 0;
    $: console.log('cc', councilCount);
    // import { texts } from './texts.js';
    import { texts } from './texts';
    export let lang = 'pl';
    const classVersion = {
        playerCard: 'player-card',
        titleCharacter: 'card-text__character',
        titlePlayer: 'card-text__player',
    };
    $: if (viewState.isVotingRunning) {
        classVersion.playerCard = 'player-card--smaller';
        classVersion.titleCharacter = 'card-text__character--smaller';
        classVersion.titlePlayer = 'card-text__player--smaller';
    } else if (isManualVisible || isLetterVisible) {
        classVersion.playerCard = 'player-card--popup';
        classVersion.titleCharacter = 'card-text__character';
        classVersion.titlePlayer = 'card-text__player';
    } else {
        classVersion.playerCard = 'player-card';
        classVersion.titleCharacter = 'card-text__character';
        classVersion.titlePlayer = 'card-text__player';
    }
</script>

<div class={classVersion.playerCard}>
    <div
        class="player-card__img player-card__img--{isLetterVisible
            ? 'mayor'
            : myChar.character}"
    >
        <div class="player-card__text">
            <span class={classVersion.titleCharacter}
                >{isLetterVisible
                    ? texts[lang].mayor.label
                    : texts[lang][myChar.character].label}</span
            >
            <span class={classVersion.titlePlayer}
                >{isLetterVisible ? 'Mayorus Maximus' : myChar.playerName}</span
            >
        </div>
    </div>
</div>
