<script>
    import PlayerCard from './PlayerCard.svelte';
    export let isManualVisible = false;
    export let myChar = {};
    import { texts } from './texts.js';
    export let lang = 'pl';
</script>

<div class="u-background--popup">
    <div class="instruction__popup instruction__popup--wide">
        <button class="btn-close" on:click={() => (isManualVisible = false)} />
        <PlayerCard {lang} {myChar} {isManualVisible} />

        <div class="instruction-content">
            <div class="instruction-content__title">
                <span class="h-main">{texts[lang].your_goals}</span>
            </div>
            <div class="instruction-content__introduction">
                <p class="p-big">
                    {texts[lang].manual_introduction}
                </p>
            </div>
            <!-- pojedyncza karta z info -->

            {#each myChar.gameLogic.objectives as objective}
                <div class="instruction-content__objective">
                    <div
                        class="objective__icon"
                        class:objective__icon--relative={objective.relative}
                    >
                        <img
                            src="https://mindlab-zulu-files.s3.eu-central-1.amazonaws.com/img/bridge-parts/{objective.icon}.png"
                            alt="objective"
                            class="u-objective-img"
                        />
                    </div>
                    <hr class="u-hr--standard" />
                    <div class="objective__content">
                        <p class="p-standard">
                            {texts[lang][myChar.character][
                                objective.description1
                            ]}
                        </p>
                        <p class="p-standard p-standard--bold">
                            {texts[lang][myChar.character][
                                objective.description2
                            ]}
                        </p>
                    </div>
                </div>
            {/each}
        </div>
    </div>
</div>
