<script>
    import { onDestroy } from 'svelte';

    onDestroy(() => {
        clearInterval(interval);
        timeOfDestroyTimer = Date.now();
        console.log(timeOfDestroyTimer);
    });
    const minutesToSeconds = (minutes) => minutes * 60;
    const secondsToMinutes = (seconds) => Math.floor(seconds / 60);
    const padWithZeroes = (number) => number.toString().padStart(2, '0');
    export let timeOfDestroyTimer = 0;
    let interval;
    export let timer = minutesToSeconds(0);
    export let timerState = true;

    function formatTime(timeInSeconds) {
        const minutes = secondsToMinutes(timeInSeconds);
        const remainingSeconds = timeInSeconds % 60;
        return `${padWithZeroes(minutes)}:${padWithZeroes(remainingSeconds)}`;
    }
    export const timerTools = {
        startTimer(duration, resume = false) {
            if (interval) {
                clearInterval(interval);
            }
            timerState = true;
            !resume ? (timer = minutesToSeconds(duration)) : (timer = duration);
            interval = setInterval(() => {
                if (timer === 0) {
                    timerState = false;
                    clearInterval(interval);
                    return;
                }
                timer -= 1;
            }, 1000);
        },
    };
    if (timerState && timer > 0) {
        const now = Date.now();
        console.log(now, '    ', timeOfDestroyTimer);
        const diff = Math.floor((now - timeOfDestroyTimer) / 1000);
        console.log(diff, '    ', timer);

        if (timer - diff > 0) {
            timerTools.startTimer(timer - diff, true);
        } else {
            timerState = false;
        }
    }
</script>

{#if timerState}
    <time>{formatTime(timer)}</time>
{:else}
    <h3 class="heading-tertiary">Koniec czasu</h3>
{/if}
