<script>
    import { CONFIG } from '../config.js';
    import Swal from 'sweetalert2';
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    export let bridgeState = {};
    export let safetyState = {};
    export let councilCount;
    import { bridgeParams } from './bridgeParams';
    import PlayerCard from './PlayerCard.svelte';
    import BridgeVisualisation from './BridgeVisualisation.svelte';
    import Solution from './Solution.svelte';
    import VotingResults from './VotingResults.svelte';
    import { texts } from './texts';
    export let lang = 'pl';
    export let viewState = {};
    export let myChar = {};
    export let role;
    export let playerList = {};
    let myVote = '';
    $: if (myChar.voteResult || myChar.voteResult === null)
        myVote = myChar.voteResult;
</script>

<div class="u-background--popup">
    <!-- głónwny container na głosowanie -->
    <div class="voting-container">
        {#if !viewState.isSolutionVisible}
            <div class="voting-container__title">
                <span class="h-main"
                    >{!viewState.isVotingOver
                        ? texts[lang].voting_title
                        : texts[lang].voting_results_title}</span
                >
            </div>
        {:else}
            <div
                class="voting-container__title voting-container__title--solution"
            >
                <span class="h-main">{texts[lang].solution_title}</span>
            </div>
        {/if}
        {#if viewState.isSolutionVisible}
            <Solution {councilCount} {lang} />
        {:else}
            {#if !viewState.isVotingOver}
                <span class="h-small">{texts[lang].voting_description}</span>
            {/if}
            <!-- container na opcje mostu i podgląd mostu -->
            <div class="voting-container__project">
                <!-- info jakie opcje są głosowane-->

                <div class="voting-options">
                    {#each bridgeParams as param}
                        {#if !param.isBoolean}
                            <div class="voting-options__item">
                                <div class="voting-item__data">
                                    <p class="p-glow">
                                        {texts[lang][param.name]}
                                    </p>
                                </div>
                                <div
                                    class="voting-item__img"
                                    style="background: url({CONFIG.filesUrl}/img/bridge-parts/{bridgeState[
                                        param.name
                                    ]}.png);"
                                />
                            </div>
                        {:else}
                            <div class="voting-options__item">
                                <div class="voting-item__data">
                                    <p class="p-glow">
                                        {texts[lang][param.name]}
                                    </p>
                                </div>
                                <div
                                    class="voting-item__img"
                                    style="background: url({CONFIG.filesUrl}/img/bridge-parts/{bridgeState[
                                        param.name
                                    ]
                                        ? param.name
                                        : param.name + '_none'}.png"
                                />
                            </div>
                        {/if}
                    {/each}
                </div>
                {#if viewState.isVotingOver}
                    <div class="voting-results">
                        <div
                            class="voting-results__status"
                            class:voting-results__status--fail={safetyState.total <
                                3}
                        />
                        <h class="h-glow"
                            >{safetyState.total < 3
                                ? texts[lang].status_fail
                                : texts[lang].status_success}</h
                        >
                        <p class="p-glow">
                            {safetyState.total < 3
                                ? texts[lang].status_fail_2
                                : texts[lang].status_success_2}
                        </p>
                    </div>
                {/if}

                <div
                    class="voting-bridge "
                    class:voting-bridge--fail={viewState.isVotingOver &&
                        safetyState.total < 3}
                    class:voting-bridge--approval={viewState.isVotingOver}
                >
                    <BridgeVisualisation {bridgeState} {viewState} />
                </div>
            </div>
            {#if !viewState.isVotingOver}
                <!-- container do złożenia głosu -->
                <div class="voting-container__decision">
                    <!-- karta głosującego gracza -->
                    <PlayerCard {myChar} {viewState} {lang} />
                    <div class="decision-mask" />
                    {#if role !== 'engineers'}
                        <span class="h-middle"
                            >{texts[lang].voting_declaration1}</span
                        >
                        <span class="h-middle"
                            >{texts[lang].voting_declaration2}</span
                        >
                        <button
                            class="btn-voting btn-voting--approve"
                            class:btn-voting--active={myVote === 'accept'}
                            on:click={() => {
                                myVote = 'accept';
                                dispatch('vote', myVote);
                            }}
                        >
                            <span class="h-middle"
                                >{texts[lang].voting_approve}</span
                            ></button
                        >
                        <button
                            class="btn-voting btn-voting--reject"
                            class:btn-voting--active={myVote === 'reject'}
                            on:click={() => {
                                myVote = 'reject';
                                dispatch('vote', myVote);
                            }}
                        >
                            <span class="h-middle"
                                >{texts[lang].voting_reject}</span
                            ></button
                        >
                    {:else}
                        <span class="h-main"
                            >{texts[lang].voting_placeholder_engineer}</span
                        >
                    {/if}
                </div>
            {:else}
                <VotingResults {playerList} {safetyState} {lang} />
            {/if}
        {/if}
    </div>
</div>
