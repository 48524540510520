<script>
    import Swal from 'sweetalert2';
    export let bridgeState = {};
    import { bridgeParams } from './bridgeParams';
    import { texts } from './texts';
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();
    export let lang = 'pl';
    export let sendMe = false;
    function sendBridge() {
        sendMe = false;

        dispatch('send_bridge');
        // dymek, że wysłano
        // Swal.fire({
        //     title: 'Wysłano!',
        //     toast: true,
        //     position: 'top-end',
        //     timer: 1000,
        //     showConfirmButton: false,
        // });
    }
    export let safetyState = {};
</script>

<footer class="footer footer--consultant">
    <div class="footer__title">
        <span class="h-middle">{texts[lang].bridge_parts}</span>
        <button
            class="btn-stone"
            class:u-attention={sendMe}
            on:click={sendBridge}
        >
            <span class="btn__text">{texts[lang].engineer.show_bridge}</span>
        </button>
    </div>
    {#each bridgeParams as param}
        {#if !param.isBoolean}
            <div class="safety-card">
                <div class="safety-card__title">
                    <span class="h-middle">{texts[lang][param.name]}</span>
                    <div>
                    <span class="h-middle"
                        >{safetyState[param.name]}</span>
                        <!-- {texts[lang].safety_points_short}</span -->
                    <!-- > -->
                    <div class="safety-card__img-safe"></div>
                    </div>
                </div>
                <hr class="u-hr--wide" />
                <div class="safety-card__content">
                    {#each param.options as option}
                        <div class="safety-item">
                            <div class="safety-card__img-magnifier"></div>
                            <input
                                type="radio"
                                bind:group={bridgeState[param.name]}
                                name={param.name}
                                value={option.title}
                                id={option.title}
                                class="radio"
                                on:change={() => (sendMe = true)}
                            />
                            <label
                                for={option.title}
                                class="safety-item__option"
                                class:safety-item__option--active={bridgeState[
                                    param.name
                                ] === option.title}
                                style="background-image: url(https://mindlab-zulu-files.s3.eu-central-1.amazonaws.com/img/bridge-parts/{option.title}.png);"
                            />
                            <div class="safety-item__data">
                                <div>
                                    <span class="h-small">{option.safety}</span>
                                    <div class="safety-card__img-safe"></div>
                                </div>
                                <span class="p-glow"
                                    >{texts[lang][option.title]}</span
                                >
                                <p class="p-small">
                                    {texts[lang][option.title + '_safety_desc']}
                                </p>
                            </div>
                        </div>
                    {/each}
                </div>
            </div>
        {/if}
    {/each}

    <div class="safety-card">
        <div class="safety-card__title">
            <span class="h-middle">{texts[lang].other}</span>
            <div>
                <span class="h-middle"
                    >{safetyState.leaderstatue +
                        safetyState.guardpost +
                        safetyState.chamoisstatue}
                    </span
                >
                <div class="safety-card__img-safe"></div>
            </div>
        </div>
        <hr class="u-hr--wide" />
        <div class="safety-card__content">
            {#each bridgeParams as param}
                {#if param.isBoolean}
                    <div class="safety-item">
                        <div class="safety-card__img-magnifier"></div>
                        <input
                            type="checkbox"
                            bind:checked={bridgeState[param.name]}
                            name={param.name}
                            value={param.isSelected}
                            id={param.name}
                            class="checkbox"
                            on:change={() => (sendMe = true)}
                        />
                        <label
                            for={param.name}
                            class="safety-item__option"
                            class:safety-item__option--active={bridgeState[
                                param.name
                            ]}
                            style="background: url(https://mindlab-zulu-files.s3.eu-central-1.amazonaws.com/img/bridge-parts/{param.name}.png)"
                        />
                        <div class="safety-item__data">
                            <div>
                                <span class="h-small"
                                    >-1
                                    </span>
                                <div class="safety-card__img-safe"></div>
    
                            </div>
                            <span class="p-glow"
                                >{texts[lang][param.name]}{bridgeState[
                                    param.name
                                ]
                                    ? ' '
                                    : ''}</span
                            >
                            <p class="p-small">
                                {texts[lang][param.name + '_safety_desc']}
                            </p>
                        </div>
                    </div>
                {/if}
            {/each}
        </div>
    </div>
</footer>
