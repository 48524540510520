<script>
    // export let isVotingOver;
    export let playerList = {};
    export let safetyState = {};
    import { texts } from './texts.js';
    export let lang = 'pl';
    let totalSum = 0;
    function calcTotalScore() {
        let _sum = 0;
        playerList.council.forEach((player) => {
            _sum = _sum + player.gameLogic.score;
        });
        return _sum;
    }
    $: if (playerList.council && playerList.council.length > 0) {
        console.log('calcculating', playerList.council);
        totalSum = calcTotalScore();
    }
</script>

<div class="voting-container__points">
    <div class="points-title">
        <span class="h-main">{texts[lang].victory_points}</span>
    </div>
    <div class="points-summary">
        <div class="points-summary__value">
            <span class="h-middle"
                >{totalSum} </span
            >
            <div class="points__img-satisfaction"></div>
        </div>
        <p class="h-small">{texts[lang].voting_summary_points}</p>

        <div class="points-summary__value">
            <span class="h-middle"
                >{safetyState.total}</span
            >
            <div class="points__img-safety"></div>
           
        </div>
        <p class="h-small">{texts[lang].voting_summary_safe}</p>
    </div>
    <!-- item z informacjami o konkrentym graczu -->
    {#each playerList.council as player}
        <div class="points-item">
            <div class="points-item__results">
                <span class="h-middle"
                    >{player.gameLogic.score}</span>
                    <div class="points__img-satisfaction"></div>

            </div>
            <div
                class="points-item__img points-item__img--{player.character}"
            />
            <div class="points-item__data">
                <span class="h-small"
                    >{texts[lang][player.character].label}</span
                >
                <p class="p-glow">{player.playerName}</p>
            </div>
        </div>
    {/each}
</div>
