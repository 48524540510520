<script>
    import { createEventDispatcher } from 'svelte';
    export let viewState = {};
    const dispatch = createEventDispatcher();
    export let playerList = {};
    export let myChar = {};
    export let isLetterVisible = false;
    export let isManualVisible = false;
    // export let isVotingRunning = false;
    import PlayerListItem from './PlayerListItem.svelte';
    import PlayerCard from './PlayerCard.svelte';
    export let role;
    import { texts } from './texts';
    export let lang = 'pl';
    export let councilCount = 0;
    export let isTrainerSummoned = false;
    $: console.log('header', playerList);
</script>

<header class="header">
    <div class="header__logo-box">
        <div class="logo-box--mindlab" />
        <div class="logo-box--game" />
    </div>
    <div class="header__menu-box">
        <input
            type="checkbox"
            id="player-list"
            class="checkbox list-checkbox"
        />
        <label for="player-list" class="list-label">
            <span class="btn__text">{texts[lang].council_label}</span>
        </label>
        <div class="players-list">
            {#each playerList.council as player}
                <PlayerListItem {lang} {player} />
            {/each}
            {#each playerList.engineers as player}
                <PlayerListItem {lang} {player} />
            {/each}
        </div>
        <button
            class="btn-stone--small"
            on:click={() => {
                isManualVisible = true;
            }}
        >
            <span class="btn__text">{texts[lang].faq}</span>
        </button>
        <button
            class="btn-stone"
            class:u-attention={isTrainerSummoned}
            on:click={() => {
                isTrainerSummoned = true;
                dispatch('summon_trainer');
            }}
        >
            <span class="btn__text">{texts[lang].summon_trainer}</span>
        </button>
        <div class="timer-box"><slot /></div>
        {#if councilCount < 5 && !isLetterVisible && !isManualVisible && !viewState.isVotingRunning && role !== 'engineers'}<button
                class="btn-letter"
                on:click={() => {
                    isLetterVisible = true;
                }}
            >
                <span class="btn__text--small">{texts[lang].mayor_letter}</span>
            </button>
        {/if}
    </div>
    <PlayerCard {lang} {myChar} bind:isLetterVisible {councilCount} />
</header>
