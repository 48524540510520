<script>
    import ObjectiveCard from './ObjectiveCard.svelte';
    export let objectives = [];
    export let bridgeState = {};
    export let character = '';
    export let lang = 'pl'
</script>

<footer class="footer">
    {#each objectives as objective (objective.id)}
        <ObjectiveCard {objective} {bridgeState} {character} {lang}/>
    {/each}
</footer>
