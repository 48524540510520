<script>
    import { texts } from './texts.js';
    export let lang = 'eng';
    export let isGameEnd = false;
    $: if (!lang) lang = 'eng';
</script>

<div class="u-background--loading">
    <div class="loading-mask" />
    <div class="loading-content">
        <div class="loading-content__logo" />
        <div class="loading-content__title">
            <div class="game-logo" />
            <span class="h-main"> {texts[lang].loading_title}</span>
        </div>
        <div class="loading-content__introduction">
            <span class="h-middle">
                {!isGameEnd
                    ? texts[lang].loading_introduction
                    : texts[lang].game_over_intro}</span
            >
        </div>
    </div>
</div>
