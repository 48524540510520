<script>
    export let councilCount = 4;
    import { texts } from './texts.js';
    export let lang = 'pl';
</script>

<div class="u-flex-row">
    <h class="h-glow"
        >{councilCount < 6
            ? texts[lang].solution_description1
            : texts[lang].solution_description2}
            </h>
    <div class="solution-points">
        <span class="h-middle"
            >{councilCount < 6
                ? texts[lang].solution_points1
                
                : texts[lang].solution_points2}</span
        >
        <div class="points__img-satisfaction"></div>
    </div>
</div>

{#if councilCount < 6}
    <div class="solution-container solution1">
        <h class="h-middle">{texts[lang].solution_heading1}</h>
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <h class="h-small">{texts[lang].solution_alternative}</h>
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <h class="h-small">{texts[lang].solution_alternative}</h>
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
    </div>

    <div class="solution-container solution2">
        <h class="h-middle">{texts[lang].solution_heading2}</h>
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
    </div>
{:else if (councilCount = 6)}
    <div class="solution-container solution3">
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
        <div class="voting-item__img" />
    </div>
{/if}
